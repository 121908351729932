// If it has parameters then it's a mixin. 
// If it doesn't make it a %placeholder and @extend it.

@mixin font($font, $fz, $fw:null){
  @if ($fw){
    font: $fw em($fz) $font;
  }
  @else{
    font: em($fz) $font;
  }
}

@mixin wh($w, $h: $w, $fz: 16){
  width: em($w, $fz);
  height: em($h, $fz);
}

@mixin whp($w, $h: $w){
  width: $w * 1%;
  height: $h * 1%;
}

@mixin pseudo{
  content: "";
  position: absolute;
}
@mixin tbma{
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
@mixin trblma{
  @include tbma;
  left: 0;
  right: 0;
  margin: auto;
}


/**
 * Vertically center
 * Just include a .inner as child
 */
@mixin vert-center{
  display: flex;
  align-items: center;
}



/**
 * module mixin
 * 
 * @param  $args...   1st: module name, rest: list of modifiers
 * @return Placeholders
 */
@mixin module($args...){
  $module: nth($args, 1);
  
  @extend %#{$module} !optional;
  @if length($args) > 1{
    @for $i from 2 through length($args){
      $item: nth($args, $i);
      @extend %#{$module}-#{$item};
    }    
  }
}


/**
 * Media query
 */
 // @mixin mq($point) {
 //   @if $point == fromMobile {
 //     @media only screen and (min-device-width: 0px) and (max-device-width: 767px) { 
 //      @content; 
 //    }
 //   }
 //   @else if $point == fromTablet {
 //     @media (min-device-width: 768px) { @content; }
 //   }
 //   @else if $point == fromDesktop {
 //     @media (min-device-width: 1024px)  { @content; }
 //   }
 // }




@mixin fromMobile{
  @media (min-width: 768px) { @content; }
}

@mixin fromTablet{
  @media (min-width: 1024px) { @content; }
}


@mixin toMobile{
 @media only screen and (max-width: 767px) { 
   @content; 
 }
}

@mixin toTablet{
  @media (max--width: 767px) { @content; }
}

@mixin toDesktop{
  @media (max-width: 1023px) { @content; }
}


@mixin resetBtn{
  outline: 0;
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  font-size: inherit;
  text-decoration: none;
}


// @mixin mod($args...){
//   $module: nth($args, 1);
//   @if length($args) > 1{
//     @for $i from 2 through length($args){
//       $item: nth($args, $i);
//       $props: map-get($module, $item);
//       @each $key, $val in $props{
//         #{$key}: $val;
//       }
//     }
//   }
// }

// @function map-set($map, $key, $value) {
//   $new: ($key: $value);
//   @return map-merge($map, $new);
// }

// $modules: ();
// @mixin createModule($name, $map){
//     $modules: map-set($modules, sup, $map);
//     @debug $modules;
// }

// @include createModule,(Text (
//   light: (
//     color: red,
//     background: blue
//   )
// ));


// @mixin modd($args...){
//   $module: nth($args, 1);
//   @if length($args) > 1{
//      @for $i from 2 through length($args){

//      }
//   }
// }

// @include modd(Text, light);