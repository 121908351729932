body{
 font-family: $ff-content;
}

h1, h2, h3, h4, h5, h6{
 // font-family: $ff-title
}

.wrapper{
}


.pages{
  max-width: 862px; 
  // max-width: 960px;
  margin: 0 auto;
  padding: 0 em(5);
  @media($toMobile){
    padding-top: em(15);
  }
  @media($fromMobile){
    padding-top: em(30);
  }
  &--formatting{
    padding-top: 0;
  }
}


html, body, .editor {
  margin: 0;
}

.flex{
  display: flex;
}


.editor{
  @media($toMobile){
    padding-top: em(15);
    margin-bottom: em(30);
  }
  @media($fromMobile){
    padding-top: em(10);
    margin-bottom: em(50);
  }
}

.editor--formatting{
  @media($fromMobile){
    padding-top: em(20);
    margin-bottom: em(50);
  }
}



.editor.fullscreen{
  textarea{
    position: fixed;
    left: 0;
    top: 0;
    width: 50%;
    height: calc(100% - 10px);
  }
  .icon-fullscreen{
    position: fixed;
    left: calc(50% - 23px);
    top: em(8);
    z-index: 1;
    margin: 0 !important;
  }
}

.result.fullscreen{
  position: fixed;
  right: 0;
  top: 0;
  width: 50%;
  height: calc(100% - 10px);
  padding-bottom: 20px;
  .md{
    // overflow: scroll;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 20px;
  }
}

.editor-clear{
  float: right;
  text-decoration: none;
  color: #0079d3;
  font-size: em(14, 13);
  padding-bottom: em(6, 13);
  &:hover{
    // text-decoration: underline;
  }
}

.title{
  $fz: 32;
  text-align: center;
  margin-top: 0;
  // margin: 0;
  @media($toMobile){
    font-size: em(20);
    margin-bottom: 0;
  }
  @media($fromMobile){
    margin-bottom: em(21.44, $fz);
  }
}

.editor, .result{
   font-size: 13px;
}
.md{
  min-height: 30px;
}

textarea, .result {
  // display: inline-block;
  // width: 49%;
  width: 100%;
  height: 100%;
  vertical-align: top;
  box-sizing: border-box;
}

.result{
  font-family: verdana, arial, helvetica, sans-serif;
}

textarea {
  border: none;
  border-right: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-family: 'Monaco', courier, monospace;

  font-size: 16px;
  padding: em(12) em(10);

  @media($toMobile){
    height: 200px;    
  }


  @media($fromMobile){
    font-size: 14px;
    padding: 20px;
    height: 300px;
  }
}

.text-default {
  font-size: 1em;
}
.text-small {
  font-size: 0.9em;
}
.text-light {
  color: #aaa;
}
.text-danger{
  color: red;
}

.fr{
  float: right;
}


.rel{
  position: relative;
}


.display{
  img{
    max-width: 100%;
    @media(max-width: 760px){
      height: auto;
    }
    // height: auto;
  }
}

// .Site__icon{
//   position: absolute;
//   top: em(14);
//   left: 0;
//   width: 60px;
// }

.icon-fullscreen-container{
  // position: absolute;
  // right: em(0);
  float: right;
  position: relative;
  bottom: em(3);
}

.icon-fullscreen{
  $fz: 18;
  font-size: em($fz);
  color: #505050;
  cursor: pointer;
  &:hover{
    color: #0eb8ff;
  }
}



.Footer{
  float: right;
}

.Footer{
  padding: em(20) 0 em(20) 0;
 
}

.Link{
  color: #aaa;
  text-decoration: none;
  display: inline-block;
  margin-left: 20px;
  font-weight: normal;
  padding: 10px 0;  
  &:hover{
    color: #666;
  }
}

/*=====================================
=            Reddit styles            =
=====================================*/


.result table {
  border: thin solid grey;
  border-collapse: collapse;
}

.result table td, #html table th {
  border: thin solid lightgrey;
}



.md{
  background-color: #fafafa;
  border: 1px solid #369;
  border-radius: 7px;
  // padding: 5px 10px;
  padding: 0px 10px;
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 400;
  color: #222222;
  max-width: 60em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  margin: 0 auto;

}

.md textarea, .md .-text, .md p, .md pre>code, .md th, .md td, .md li{
  line-height: 20px;
  
}

.md code{
  background: #ffffff;
  padding: 0 4px;
  margin: 0 2px;
  white-space: nowrap;
  word-break: normal;
  border: 1px solid #e6e6de;
  border-radius: 2px;
}

.md hr{
  border: 0;
  color: transparent;
  background: #c5c1ad;
  height: 2px;
  padding: 0;
}

.md .-blocks, .md .-lists, .md pre, 
.md blockquote, 
// .md table, 
.md p, .md ul, .md ol{
  margin-top: 5px;
  margin-bottom: 5px;
}
.md th{
  padding: 4px 9px;
  font-weight: 600;
  font-style: inherit;
}

.md th, .md td{
  border: 1px solid #e5e3da;
  padding: 4px 9px;
}

.md blockquote {
    border-left: 2px solid #c5c1ad;
    padding: 0 8px;
    margin-left: 5px;
    color: #4f4f4f;
}

.md a{
  color: #0079d3;
  text-decoration: none;
}

.md del{
  color: #4f4f4f;
}



.md sup {
    font-size: 0.86em;
    line-height: 0
}

.md .-headers,.md h1,.md h2,.md h3,.md h4,.md h5,.md h6 {
    border: 0;
    color: inherit;
    -webkit-font-smoothing: antialiased
}

.md .-headers code,.md h1 code,.md h2 code,.md h3 code,.md h4 code,.md h5 code,.md h6 code {
    font-size: inherit
}

.md blockquote,.md del {
    color: #4f4f4f
}

.md a {
    color: #0079d3;
    text-decoration: none
}

.md a del {
    color: inherit
}

.md h6 {
    text-decoration: underline
}

.md em {
    font-style: italic;
    font-weight: inherit
}

.md th,.md strong,.md .-headers,.md h1,.md h2,.md h3,.md h4,.md h5,.md h6 {
    font-weight: 600;
    font-style: inherit
}

.md h2,.md h4 {
    font-weight: 500
}

.md,.md h6 {
    font-weight: 400
}



.md {
    font-size: 1.0769230769230769em
}

.md h1,.md h2 {
    font-size: 1.2857142857142858em;
    line-height: 1.3888888888888888em;
    margin-top: 0.8333333333333334em;
    margin-bottom: 0.8333333333333334em
}

.md h3,.md h4 {
    font-size: 1.1428571428571428em;
    line-height: 1.25em;
    margin-top: 0.625em;
    margin-bottom: 0.625em
}

.md h5,.md h6 {
    font-size: 1em;
    line-height: 1.4285714285714286em;
    margin-top: 0.7142857142857143em;
    margin-bottom: 0.35714285714285715em
}

/*=====  End of Reddit styles  ======*/
