.Footer{
  &.-default{
    width: em($site-width);
    margin: 0 auto;
  }

  // --- children ---
  ._content{

  }
}