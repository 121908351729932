
/*=============================
=            Modal            =
=============================*/
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
}

// .modal-container {
//     width: 300px;
//     margin: 40px auto 0;
//     padding: 20px 30px;
//     background-color: #fff;
//     border-radius: 2px;
//     box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
//     transition: all .3s ease;
//     font-family: Helvetica, Arial, sans-serif;
// }

.modal-container {
    width: 350px;
    margin: 40px auto 0;
    padding: 30px 33px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
}



.modal-header h3 {
    margin-top: 0;
    font-family: inherit;
}

.modal-body {
    margin: 20px 0;
    p{
      font-size: em(14);
      color: #888;
    }
    .sml-text{
      font-size: em(14);
    }
}

.modal-error{
  $fz: 14;
  color: red;
  font-size: em($fz);
  padding-top: em(6, $fz);
}

.text-inline-block{
  display: inline-block;
}
.text-left{
  text-align: left;
}
.text-right {
    text-align: right;
}

.form-label {
    display: block;
    margin-bottom: 1em;
}

.form-label > .form-control {
    margin-top: 0.5em;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.5em 1em;
    line-height: 1.5;
    border: 1px solid #ddd;
}

.modal-enter, .modal-leave {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}


/*=====  End of Modal  ======*/